export const BG_COVER = `https://ik.imagekit.io/farahoktarina/widhistievanny/Foto%20Cover%20Portrait.jpg?updatedAt=1708671660538`;
export const BG_WELCOMING_SECTION = `https://ik.imagekit.io/farahoktarina/widhistievanny/Foto%20Cover%20Dalam%20Portrait.jpg?updatedAt=1708671270258`;
export const BG_COUNTING = `https://user-images.githubusercontent.com/10141928/146292235-21d28e45-72b7-41e1-90ac-26a3605529e2.jpg`;
export const BG_IG_FILTER = `https://ik.imagekit.io/farahoktarina/widhistievanny/COVER%20WIDHI%20STIEVANNY.jpg?updatedAt=1709630658603`;
export const BG_DESKTOP_VIEW = `https://ik.imagekit.io/farahoktarina/widhistievanny/Foto%20Desktop%20View%20Landscape.jpg?updatedAt=1708671270377`;

export const IMG_AYAT = `https://ik.imagekit.io/farahoktarina/widhistievanny/Revisi_Foto%20Footer%20Portrait.jpg?updatedAt=1709627401707`;
import logo from '@/assets/icons/logo.png'
import logoInvert from '@/assets/icons/logo-invert.png'
export const IMG_LOGO = logo;
export const IMG_LOGO_INVERT = logoInvert;

export const IMG_MAN = `https://ik.imagekit.io/farahluthfioktarina/widhistievanny/Revisi%20Foto%20Groom%20Landscape.jpg?updatedAt=1709894522103`;
export const IMG_GIRL = `https://ik.imagekit.io/farahluthfioktarina/widhistievanny/Revisi%20Foto%20Bride%20Landscape.jpg?updatedAt=1709894522012`;
export const IMG_COUPLE = `https://ik.imagekit.io/farahoktarina/widhistievanny/Foto%20Bride%20Groom%20Landscape.jpg?updatedAt=1708671270171`;
export const IMG_LOVE_STORY = `https://user-images.githubusercontent.com/10141928/146289521-e97786dc-98a1-43d3-9b0f-e9353b168766.jpg`;

export const IMG_PHOTO_1 = `https://ik.imagekit.io/farahoktarina/widhistievanny/Foto%20Cover%20Luar%20Gallery_1%20.jpg?updatedAt=1708671270262`;
export const IMG_PHOTO_2 = `https://ik.imagekit.io/farahoktarina/widhistievanny/Foto%20Cover%20Luar%20Gallery_2.jpg?updatedAt=1708671269672`;
export const IMG_PHOTO_CONTENT = `https://ik.imagekit.io/farahoktarina/widhistievanny/Foto%20Cover%20Dalam%20Gallery%20Crop.jpg?updatedAt=1708681496454`;

export const IMG_COVID = {
  id: `https://user-images.githubusercontent.com/103095433/241068547-20f04f29-1531-4bc2-a5e5-c7e3ce1c2805.png`,
  en: `https://user-images.githubusercontent.com/103095433/241068561-c7d17d12-c32f-44a1-834a-af73e655b0b6.jpg`,
};

export const LOGO_INVITATO = `https://ik.imagekit.io/invitatoid/global-assets/invitato_hs3nyqsuwy_.png?updatedAt=1638541184695`;
